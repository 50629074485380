import React, { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { convertDate } from "../../utils/convertDateTime";
import { Main, Aside, Section } from "../../layout/index";
import Spinner from "../../utils/Spinner/Spinner";
import NewsItem from "./NewsItem/NewsItem";
import parse from "html-react-parser";
import "./News.css";

const News = ({ location }) => {
  const [loadingPost, setLoadingPost] = useState(true);
  const [activePost, setActivePost] = useState();

  const { data, loading } = useFetch(
    "https://posts.marindentalsociety.org/wp-json/wp/v2/news?per_page=6&_embed"
  );

  useEffect(() => {
    setLoadingPost(true);
    if (!loading && location.state) {
      let id = location.state.routerPostID;
      if (!loading) {
        let index = data.findIndex((post) => post.id === id);
        setActivePost(data[index]);
        setLoadingPost(false);
      }
    } else if (!loading && !location.state) {
      setActivePost(data[0]);
      setLoadingPost(false);
    }
  }, [data, loading, location.state]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 160,
      behavior: "smooth",
    });
  };

  return (
    <Main slug="news" title="MCDS NEWS" bodyClass="news-subpage">
      <Aside showNav={false} header="LATEST NEWS">
        <div className="news-feed-container">
          {loading && <Spinner />}
          {!loading &&
            data.map((post) => (
              <div
                className={`news-feed-item ${
                  activePost === post.id ? "active" : null
                }`}
                key={post.id}
                onClick={() => {
                  setActivePost(post);
                  scrollToTop();
                }}
              >
                <h4 className="news-feed-title">{post.title.rendered}</h4>
                {parse(post.excerpt.rendered)}
                <p className="news-feed-date">
                  Posted {convertDate(post.date)}
                </p>
              </div>
            ))}
        </div>
      </Aside>
      <Section slug="join-renew">
        <NewsItem loadingPost={loadingPost} activePost={activePost} />
      </Section>
    </Main>
  );
};

export default News;
