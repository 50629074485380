import { useState } from "react";
import { Link } from "react-router-dom";
import ExpandMore from "../../../assets/icons/ExpandMore";
import styles from "./MembersNav.module.css";

const MembersNav = () => {
  const [membershipOpen, setMembershipOpen] = useState();
  const [educationOpen, setEducationOpen] = useState(false);
  const [classifiedsOpen, setClassifiedsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);

  // Accessibility - prevent keyboard tab from closing nav menus
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setMembershipOpen(false);
      setEducationOpen(false);
      setClassifiedsOpen(false);
      setAboutOpen(false);
    }
  };

  return (
    <nav className={styles.nav}>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setMembershipOpen(true)}
        onMouseLeave={() => setMembershipOpen(false)}
        onClick={() => setMembershipOpen(!membershipOpen)}
        onFocus={() => setMembershipOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/members/join-renew">
          Membership
          <ExpandMore />
        </Link>
        <div
          className={`${styles.submenu} ${
            !membershipOpen ? styles.inactive : styles.membershipActive
          }`}
        >
          <Link to="/members/join-renew" className={styles.submenuLink}>
            Join / Renew
          </Link>
          <Link to="/members/member-benefits" className={styles.submenuLink}>
            Member Benefits
          </Link>
          <Link to="/members/member-directory" className={styles.submenuLink}>
            Member Directory
          </Link>
          <Link to="/members/leadership" className={styles.submenuLink}>
            Leadership
          </Link>
          <Link to="/members/emergency-panel" className={styles.submenuLink}>
            Emergency Panel
          </Link>
        </div>
      </div>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setEducationOpen(true)}
        onMouseLeave={() => setEducationOpen(false)}
        onClick={() => setEducationOpen(!educationOpen)}
        onFocus={() => setEducationOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/members/ce-requirements">
          CE / CPR
          <ExpandMore />
        </Link>
        <div
          className={`${styles.submenu} ${
            !educationOpen ? styles.inactive : styles.educationActive
          }`}
        >
          <Link to="/members/ce-requirements" className={styles.submenuLink}>
            CE Requirements
          </Link>
          <Link to="/members/ce-courses" className={styles.submenuLink}>
            CE Courses
          </Link>
          <Link to="/members/cpr-certification" className={styles.submenuLink}>
            CPR Certification
          </Link>
        </div>
      </div>
      <Link to="/members/calendar" className={styles.navLink}>
        Calendar
      </Link>
      <Link to="/members/news" className={styles.navLink}>
        News
      </Link>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setClassifiedsOpen(true)}
        onMouseLeave={() => setClassifiedsOpen(false)}
        onClick={() => setClassifiedsOpen(!classifiedsOpen)}
        onFocus={() => setClassifiedsOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/members/employment-opportunities">
          Classifieds
          <ExpandMore />
        </Link>
        <div
          className={`${styles.submenu} ${
            !classifiedsOpen ? styles.inactive : styles.classifiedsActive
          }`}
        >
          <Link
            to="/members/employment-opportunities"
            className={styles.submenuLink}
          >
            Employment Opportunities
          </Link>
          <Link
            to="/members/practice-opportunities"
            className={styles.submenuLink}
          >
            Practice Opportunities
          </Link>
        </div>
      </div>
      <Link to="/members/advertising" className={styles.navLink}>
        Advertising
      </Link>
      <Link to="/members/foundation" className={styles.navLink}>
        MCDCF
      </Link>
      <Link to="/members/resources" className={styles.navLink}>
        Resources
      </Link>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setAboutOpen(true)}
        onMouseLeave={() => setAboutOpen(false)}
        onClick={() => setAboutOpen(!aboutOpen)}
        onFocus={() => setAboutOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/members/history">
          About
          <ExpandMore />
        </Link>
        <div
          className={`${styles.submenu} ${
            !aboutOpen ? styles.inactive : styles.aboutActive
          }`}
        >
          <Link to="/members/board-and-staff" className={styles.submenuLink}>
            Board & Staff
          </Link>
          <Link to="/members/history" className={styles.submenuLink}>
            History
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default MembersNav;
