import React from "react";
import { Route } from "react-router-dom";
import {
  EmergencyPanel,
  Leadership,
  Advertising,
  MemberDirectory,
  BoardAndStaff,
  Resources,
  CERequirements,
  CECourses,
  CPRCertification,
  Calendar,
  News,
  EmploymentOpportunities,
  PracticeOpportunities,
  History,
  JoinRenew,
  MemberBenefits,
  Foundation,
  LegalDisclaimer,
  PrivacyPolicy,
} from "../pages/index";
import MemberHome from "../pages/Home/MemberHome";

const MemberRoutes = () => {
  return (
    <>
      <Route path="/members" component={MemberHome} exact />
      <Route path="/members/emergency-panel" component={EmergencyPanel} />
      <Route path="/members/leadership" component={Leadership} />
      <Route path="/members/join-renew" component={JoinRenew} />
      <Route path="/members/member-benefits" component={MemberBenefits} exact />
      <Route path="/members/member-directory" component={MemberDirectory} />
      <Route path="/members/ce-requirements" component={CERequirements} />
      <Route path="/members/ce-courses" component={CECourses} exact />
      <Route path="/members/cpr-certification" component={CPRCertification} />
      <Route path="/members/calendar" component={Calendar} />
      <Route path="/members/news" component={News} exact />
      <Route
        path="/members/employment-opportunities"
        component={EmploymentOpportunities}
      />
      <Route
        path="/members/practice-opportunities"
        component={PracticeOpportunities}
      />
      <Route path="/members/advertising" component={Advertising} />
      <Route path="/members/foundation" component={Foundation} />
      <Route path="/members/resources" component={Resources} />
      <Route path="/members/board-and-staff" component={BoardAndStaff} />
      <Route path="/members/history" component={History} />
      <Route path="/members/privacy-policy" component={PrivacyPolicy} />
      <Route path="/members/legal-disclaimer" component={LegalDisclaimer} />
    </>
  );
};

export default MemberRoutes;
