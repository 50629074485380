import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import Affiliates from "../../../components/Affiliates/Affiliates";
import Taylor from "../../../assets/drs/taylor.jpg";
import DrBrooks from "../../../assets/drs/brooks.png";
import Amaya from "../../../assets/drs/amaya.jpg";
import Goldstein from "../../../assets/drs/goldstein.jpg";
import Miller from "../../../assets/drs/miller.jpg";
import Wilsey from "../../../assets/drs/wilsey.jpg";
import Contreras from "../../../assets/drs/contreras.jpg";
import Brinker from "../../../assets/drs/brinker.png";
import Green from "../../../assets/drs/green.jpg";
import "./BoardAndStaff.css";

const BoardAndStaff = () => {
  const navList = [
    { label: "Board & Staff", value: "board-and-staff", key: 1 },
    { label: "History", value: "history", key: 2 },
  ];

  return (
    <Main
      slug="board-staff"
      title="BOARD AND STAFF"
      bodyClass="board-staff-subpage"
    >
      <Aside navList={navList} header="ABOUT US" active={1}>
        <div className="affiliates-wide-wrapper">
          <Affiliates />
        </div>
      </Aside>
      <Section>
        <div className="board-container" id="board">
          <h2 className="subpage-content-title board-title">
            Board of Directors
          </h2>
          <div className="board">
            <div className="board-staff-box">
              <img src={Taylor} alt="Doctor Sophie Taylor" />
              <div className="box-text">
                <h3>President</h3>
                <h4>Sophie Taylor, DDS</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={DrBrooks} alt="Doctor Jennifer Brooks" />
              <div className="box-text">
                <h3>President Elect</h3>
                <h4>Jennifer Brooks, DMD</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={Contreras} alt="Doctor Raul Contreras" />
              <div className="box-text">
                <h3>Treasurer / Secretary</h3>
                <h4>Raul Contreras, DMD</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={Miller} alt="Doctor Greg Miller" />
              <div className="box-text">
                <h3>Director-at-Large</h3>
                <h4>Greg Miller, DDS</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={Wilsey} alt="Doctor Sarah Wilsey" />
              <div className="box-text">
                <h3>Director-at-Large</h3>
                <h4>Sarah Wilsey, DDS</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img
                src={Goldstein}
                alt="Doctor Rosemarie Goldstein"
                id="goldstein"
              />
              <div className="box-text">
                <h3>Director-at-Large</h3>
                <h4>Rosemarie Goldstein, DDS</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={Amaya} alt="Doctor Ana Amaya" />
              <div className="box-text">
                <h3>Trustee</h3>
                <h4>Ana Amaya, DDS</h4>
              </div>
            </div>
            <div className="board-staff-box">
              <img src={Brinker} alt="Doctor Rachel Brinker" />
              <div className="box-text">
                <h3>Immediate Past President</h3>
                <h4>Rachel Brinker, DMD</h4>
              </div>
            </div>
          </div>
          <h2 className="subpage-content-title staff-title" id="staff">
            Staff
          </h2>
          <div className="staff">
            <div className="board-staff-box">
              <img
                src={Green}
                alt="Executive Director Carissa Green"
                id="carissa-green"
              />
              <div className="box-text">
                <h3>Executive Director</h3>
                <h4>Carissa Green</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="affiliates-small-wrapper">
          <Affiliates />
        </div>
      </Section>
    </Main>
  );
};

export default BoardAndStaff;
