import React from "react";
import HeroBanner from "./HeroBanner/HeroBanner";
import BannerBoxesGuest from "./BannerBoxesGuest/BannerBoxesGuest";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import HomeContact from "./HomeContact/HomeContact";

const GuestsHome = () => {
  return (
    <main style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <HeroBanner />
      <BannerBoxesGuest />
      <WhoWeAre />
      <HomeContact />
    </main>
  );
};

export default GuestsHome;
