import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import MembersNav from "./MembersNav";
import GuestsNav from "./GuestsNav";
import SubList from "./SubList";
import Hamburger from "../../../assets/icons/header/Hamburger";
import Close from "../../../assets/icons/header/Close";
import styles from "./Mobile.module.css";

const Mobile = ({ members }) => {
  const navRef = useRef();
  const subRef = useRef();
  const history = useHistory();
  const [subListOpen, setSubListOpen] = useState();
  const [mobileOpen, setMobileOpen] = useState();
  const [subList, setSubList] = useState([]);

  const membership = [
    { name: "Join/Renew", slug: "join-renew" },
    { name: "Member Benefits", slug: "member-benefits" },
    { name: "Member Directory", slug: "member-directory" },
    { name: "Leadership", slug: "leadership" },
    { name: "Emergency Panel", slug: "emergency-panel" },
  ];
  const ceCPR = [
    { name: "CE Requirements", slug: "ce-requirements" },
    { name: "CE Courses", slug: "ce-courses" },
    { name: "CPR Certification", slug: "cpr-certification" },
  ];
  const classifieds = [
    { name: "Employment Opportunities", slug: "employment-opportunities" },
    { name: "Practice Opportunities", slug: "practice-opportunities" },
  ];
  const aboutUs = [
    { name: "Board & Staff", slug: "board-and-staff" },
    { name: "History", slug: "history" },
  ];

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !subListOpen &&
        mobileOpen &&
        navRef.current &&
        !navRef.current.contains(e.target)
      ) {
        setMobileOpen(false);
      } else if (
        subListOpen &&
        mobileOpen &&
        subRef.current &&
        navRef.current &&
        (!subRef.current.contains(e.target) ||
          !navRef.current.contains(e.target))
      ) {
        setMobileOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [mobileOpen, subListOpen]);

  const handleClick = (e) => {
    switch (e.target.id) {
      case "membership":
        setSubList(membership);
        setSubListOpen(true);
        break;
      case "ce-cpr":
        setSubList(ceCPR);
        setSubListOpen(true);
        break;
      case "classifieds":
        setSubList(classifieds);
        setSubListOpen(true);
        break;
      case "about-us":
        setSubList(aboutUs);
        setSubListOpen(true);
        break;
      default:
        history.push(e.target.dataset.path);
        setMobileOpen(false);
        setSubListOpen(false);
    }
  };

  return (
    <header className={styles.header}>
      <span
        className={styles.hamburger}
        onClick={() => {
          setMobileOpen(true);
          setSubListOpen(false);
        }}
      >
        <Hamburger />
      </span>
      <h2
        className={styles.title}
        onClick={() => {
          members ? history.push("/members") : history.push("/guests");
        }}
      >
        Marin County Dental Society
      </h2>
      {mobileOpen && (
        <nav className={styles.container} ref={navRef}>
          <span className={styles.close} onClick={() => setMobileOpen(false)}>
            <Close />
          </span>
          {members && <MembersNav handleClick={handleClick} />}
          {!members && <GuestsNav handleClick={handleClick} />}
          {subListOpen && (
            <SubList
              ref={subRef}
              subList={subList}
              members={members}
              handleClick={handleClick}
              handleBack={() => setSubListOpen(false)}
            />
          )}
        </nav>
      )}
    </header>
  );
};

export default Mobile;
