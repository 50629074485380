import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import Spinner from "../../../utils/Spinner/Spinner";
import DefaultNews from "../../../assets/default-news.jpg";
import "./HomeNews.css";

const HomeNews = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://posts.marindentalsociety.org/wp-json/wp/v2/news?per_page=3&_embed"
      )
      .then((res) => setPosts(res.data))
      .then(() => setLoading(false));
  }, []);

  return (
    <section className="announcements">
      <h2 className="announcements-title">News</h2>
      <div className="announcements-content">
        {loading ? (
          <Spinner />
        ) : (
          posts.map((post) => {
            return (
              <div className="announcement-box" key={post.id}>
                {post._embedded ? (
                  <img
                    src={post._embedded["wp:featuredmedia"][0].source_url}
                    alt="news"
                    className="announcement-image"
                  />
                ) : (
                  <img
                    src={DefaultNews}
                    alt="news"
                    className="announcement-image"
                  />
                )}
                <h3 className="announcement-title">{post.title.rendered}</h3>
                <div className="announcement-content">
                  {parse(post.excerpt.rendered)}
                </div>
                <Link
                  className="announcement-button-link"
                  to={{
                    pathname: "/members/news",
                    state: { routerPostID: post.id },
                  }}
                >
                  <button className="announcement-button">Read More</button>
                </Link>
              </div>
            );
          })
        )}
      </div>
      <button
        className="news-link-button"
        onClick={() => history.push("/members/news")}
      >
        View All News
      </button>
    </section>
  );
};

export default HomeNews;
