import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import Affiliates from "../../../components/Affiliates/Affiliates";
import LogoGreen from "../../../assets/logo/logo-green.png";
import "./History.css";

const History = () => {
  const navList = [
    { label: "Board & Staff", value: "board-and-staff", key: 1 },
    { label: "History", value: "history", key: 2 },
  ];

  return (
    <Main slug="history" title="MCDS HISTORY" bodyClass="history-subpage">
      <Aside navList={navList} header="ABOUT US" active={2}>
        <div className="affiliates-wide-wrapper">
          <Affiliates />
        </div>
      </Aside>
      <Section>
        <div className="our-story-container">
          <h2 className="subpage-content-title">Our Story</h2>
          <article>
            <img src={LogoGreen} alt="MCDS logo" id="history-logo" />
            <p>
              The MCDS was founded by the Dentists of Marin County in 1932 and
              chartered by the California State Dental Association, and
              recognized as the 18th District Dental Society. During that time,
              Robert B. Lovell and Philip S. Pacheco were elected President and
              Secretary, respectively. There were a total of 9 members. The
              meetings were held monthly at some of the popular restaurants in
              the County, such as the Travelers Inn, Deer Park Villa, and the
              Marin Golf and Country Club. The Society kept growing steadily
              with the expansion of the County. At the request of the American
              Dental Association, the Society was incorporated in 1959. In 1940,
              there were 30 members; in 1950, 55 members; in 1960, 90 members;
              at present the membership stands at almost 300!{" "}
            </p>
            <p>
              The MCDS serves the populations of Belvedere, Bolinas, Corte
              Madera, Fairfax, Forest Knolls, Greenbrae, Hamilton, Ignacio,
              Inverness, Kentfield, Lagunitas, Larkspur, Marin City, Marshall,
              Mill Valley, Muir Beach, Nicascio, Novato, Olema, Pt. Reyes
              Station, Ross, San Anselmo, San Rafael, Sausalito, Stinson Beach,
              Terra Linda, Tiburon, Tomales, and Woodacre.
            </p>
          </article>
        </div>
        <div className="affiliates-small-wrapper">
          <Affiliates />
        </div>
      </Section>
    </Main>
  );
};

export default History;
