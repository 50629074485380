import React from "react";
import { Route } from "react-router-dom";
import {
  Advertising,
  Emergencies,
  ChildrensCorner,
  Fluoridation,
  FindADentist,
  BoardAndStaff,
  EmploymentOpportunities,
  PracticeOpportunities,
  History,
  Foundation,
  LegalDisclaimer,
  PrivacyPolicy,
} from "../pages/index";
import GuestsHome from "../pages/Home/GuestsHome";

const GuestRoutes = () => {
  return (
    <>
      <Route path="/guests" component={GuestsHome} exact />
      <Route path="/board-and-staff" component={BoardAndStaff} />
      <Route path="/history" component={History} />
      <Route path="/find-a-dentist" component={FindADentist} />
      <Route path="/foundation" component={Foundation} />
      <Route path="/advertising" component={Advertising} />
      <Route
        path="/employment-opportunities"
        component={EmploymentOpportunities}
      />
      <Route path="/practice-opportunities" component={PracticeOpportunities} />

      <Route path="/fluoridation" component={Fluoridation} />
      <Route path="/childrens-corner" component={ChildrensCorner} />
      <Route path="/emergencies" component={Emergencies} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/legal-disclaimer" component={LegalDisclaimer} />
    </>
  );
};

export default GuestRoutes;
