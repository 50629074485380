import React from "react";
import HeroBanner from "./HeroBanner/HeroBanner";
import BannerBoxes from "./BannerBoxes/BannerBoxes";
import Events from "./Events/Events";
import HomeNews from "./HomeNews/HomeNews";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import HomeContact from "./HomeContact/HomeContact";

const MemberHome = () => {
  return (
    <main style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <HeroBanner />
      <BannerBoxes />
      <Events />
      <HomeNews />
      <WhoWeAre />
      <HomeContact />
    </main>
  );
};

export default MemberHome;
