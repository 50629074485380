import React from "react";
import { Main, Aside, Section } from "../../layout/index";
import "./Resources.css";

const Resources = () => {
  return (
    <Main slug="resources" title="RESOURCES">
      <Aside header="RESOURCES" showNav={false} />
      <Section slug="join-renew">
        <div className="resources-general" id="general">
          <h2 className="subpage-content-title">General Reference</h2>
          <div className="">
            <ul className="general-box">
              <li>
                <a
                  href="http://www.aaomp.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Academy of Oral & Maxillofacial Pathology
                </a>
              </li>
              <li>
                <a
                  href="http://www.aapd.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Academy of Pediatric Dentistry
                </a>
              </li>
              <li>
                <a
                  href="http://www.aae.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Association of Endodontists
                </a>
              </li>
              <li>
                <a
                  href="http://www.aaoms.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Association of Oral & Maxillofacial Surgeons
                </a>
              </li>
              <li>
                <a
                  href="https://www.aaoinfo.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Association of Orthodontists
                </a>
              </li>
              <li>
                <a
                  href="http://www.aap.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Association of Peridontology
                </a>
              </li>
              <li>
                <a
                  href="http://www.aaphd.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Association of Public Health Dentistry
                </a>
              </li>

              <li>
                <a
                  href="http://www.prosthodontics.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American College of Prosthodontists
                </a>
              </li>
              <li>
                <a
                  href="http://www.ada.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Dental Association
                </a>
              </li>
              <li>
                <a
                  href="https://www.dbc.ca.gov/about_us/lawsregs/laws.shtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CA Business & Professional Code
                </a>
              </li>
              <li>
                <a
                  href="http://www.cagd.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Academy of General Dentistry
                </a>
              </li>
              <li>
                <a
                  href="https://www.cda.org/Home/Practice/Practice-Support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Association Practice Support Center
                </a>
              </li>
              <li>
                <a
                  href="http://www.cda.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Association
                </a>
              </li>
              <li>
                <a
                  href="http://www.cdafoundation.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Association Foundation
                </a>
              </li>
              <li>
                <a
                  href="http://www.cdha.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Hygienists' Association
                </a>
              </li>

              <li>
                <a
                  href="http://www.cdla.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Library Association
                </a>
              </li>
              <li>
                <a
                  href="http://www.cspd.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Society of Pediatric Dentists
                </a>
              </li>
              <li>
                <a
                  href="http://www.calperio.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Society of Periodontists
                </a>
              </li>
              <li>
                <a
                  href="http://www.dloac.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Laboratory Owners Association of America
                </a>
              </li>
              <li>
                <a
                  href="http://www.hrh.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Health Rights Hotline
                </a>
              </li>
              <li>
                <a
                  href="http://www.mcdcf.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marin County Dental Care Foundation
                </a>
              </li>
              <li>
                <a
                  href="http://www.pcsortho.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pacific Coast Society of Orthodontists
                </a>
              </li>
              <li>
                <a
                  href="https://www.tdicinsurance.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Dentists Insurance Company
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="resources-regulatory" id="regulatory">
          <h2 className="subpage-content-title">Regulatory Reference</h2>
          <div className="">
            <ul className="regulatory-box">
              <li>
                <a
                  href="https://www.cda.org/Home/Practice/Practice-Support/Regulatory-Compliance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Dental Society Regulatory Compliance
                </a>
              </li>
              <li>
                <a
                  href="https://www.dbc.ca.gov/consumers/index.shtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dental Board of California Consumer Affairs
                </a>
              </li>
              <li>
                <a
                  href="https://www.dmhc.ca.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Department of Managed Health Care
                </a>
              </li>
              <li>
                <a
                  href="https://www.cda.org/Home/Public/Kindergarten-Oral-Health-Requirement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  California Kindergarten Oral Health Requirement
                </a>
              </li>
              <li>
                <a
                  href="https://www.medi-cal.ca.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Medi-Cal
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default Resources;
