import { Main, Aside, Section } from "../../../layout/index";
import { Posts, MakePost } from "../../../components/index";
import "./EmploymentOpportunities.css";

const PracticeOpportunities = () => {
  const navList = [
    {
      label: "Employment Opportunities",
      value: "employment-opportunities",
      key: 1,
    },
    {
      label: "Practice Opportunities",
      value: "practice-opportunities",
      key: 2,
    },
  ];

  return (
    <Main slug="employment" title="EMPLOYMENT OPPORTUNITIES">
      <Aside header="CLASSIFIEDS" navList={navList} active={1}>
        <MakePost />
      </Aside>
      <Section>
        <Posts categoryID={1} />
      </Section>
    </Main>
  );
};

export default PracticeOpportunities;
